/* stylelint-disable */
@mixin button($color, $text-color: color-yiq($color), $square: false) {
    background: $color;
    border: rem-calc(1) solid $color;
    color: $text-color;
    display: block;
    font-family: $font-sans-serif;
    font-weight: 500;
    overflow: hidden;
    padding-bottom: rem-calc(15);
    padding-top: rem-calc(15);
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    z-index: 1;

    @if $square {
        border-radius: rem-calc(3);
        font-size: rem-calc(15);
        line-height: rem-calc(15);
    } @else {
        border-radius: rem-calc(100);
        font-size: rem-calc(14);
        letter-spacing: rem-calc(2);
        line-height: rem-calc(20);
        text-transform: uppercase;
    }

    &:hover {
        color: $text-color;
        background: adjust-color($color, $lightness: 5%);
        border-color: adjust-color($color, $lightness: 5%);
    }

    &:not(:disabled):not(.disabled) {
        &:focus,
        &:active,
        &:active:focus,
        &.active:focus {
            box-shadow: 0 0 0 rem-calc(3) rgba($accent, 0.25);
        }

        &:active,
        &:focus {
            color: lighten($text-color, 5%);
            background-color: darken($color, 5%);
            border-color: darken($color, 5%);
        }
    }

    &.disabled,
    &:disabled {
        color: $white;
        background-color: $gray;
        border-color: $gray;

        &:focus,
        &:active {
            box-shadow: none;
        }
    }

    @if $square == false {
        @include media-breakpoint-up(lg) {
            font-size: rem-calc(18);
            padding-top: rem-calc(21);
            padding-bottom: rem-calc(21);

            &.btn-narrow {
                font-size: rem-calc(16);
                padding-top: rem-calc(13);
                padding-bottom: rem-calc(13);
            }
        }
    }
}

@mixin button-outline($color, $text-color-hover: color-yiq($color), $square: false) {
    background: linear-gradient(to left, transparent 50%, $color 50%);
    background-position: right center;
    background-size: 205% 105%;
    border: rem-calc(1) solid $color;
    color: $color;
    display: block;
    font-family: $font-sans-serif;
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    overflow: hidden;
    padding-bottom: rem-calc(15);
    padding-top: rem-calc(15);
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s;
    z-index: 1;

    @if $square {
        border-radius: rem-calc(3);
        font-weight: 400;
    } @else {
        border-radius: rem-calc(100);
        font-weight: 500;
        letter-spacing: rem-calc(2);
    }

    &:hover {
        color: $text-color-hover;
        text-decoration: none;
        background-color: transparent;
        background-position: left center;
        border-color: $color;
    }

    &:not(:disabled):not(.disabled) {
        &:focus,
        &:active,
        &:active:focus,
        &.active:focus {
            box-shadow: 0 0 0 rem-calc(3) rgba($accent, 0.25);
        }

        &:focus {
            color: $text-color-hover;
            text-decoration: none;
            background-color: transparent;
            background-position: left center;
            border-color: $color;
        }
    }

    &.disabled,
    &:disabled {
        color: $gray;
        background: linear-gradient(to left, transparent 50%, $color 50%);
        background-position: right center;
        background-size: 205% 105%;
        border-color: $gray;
        pointer-events: none;

        &:focus,
        &:active {
            box-shadow: none;
        }
    }

    @if $square == false {
        @include media-breakpoint-up(lg) {
            font-size: rem-calc(18);
            padding-top: rem-calc(21);
            padding-bottom: rem-calc(21);

            &.btn-narrow {
                font-size: rem-calc(16);
                padding-top: rem-calc(13);
                padding-bottom: rem-calc(13);
            }
        }
    }
}

@mixin button-link($color: $accent) {
    color: $color;
    border: 0;
    padding: 0;
    vertical-align: baseline;

    &:hover {
        color: adjust-color($color, $lightness: 5%);
    }
}

@mixin button-fancy-link() {
    @include button-outline($primary);

    @include media-breakpoint-up(md) {
        display: inline-block;
        padding-left: rem-calc(42);
        padding-right: rem-calc(42);
    }

    @include media-breakpoint-up(lg) {
        background: none;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: linear-gradient(to right, $accent, $accent 50%, $primary 50%);
        background-position: 100%;
        background-size: 200% 100%;
        border: none;
        color: $primary;
        display: inline-block;
        font-size: rem-calc(14);
        font-weight: 600;
        letter-spacing: rem-calc(2);
        line-height: 1.15;
        padding: rem-calc(11 0 11 62);
        position: relative;
        text-align: left;
        -webkit-text-fill-color: transparent;
        transition: padding 800ms ease;

        &:hover {
            background-position: 0%;
            padding-left: 0;
            padding-right: rem-calc(62);
            transition: all 1000ms cubic-bezier(0, 0, 0.23, 1);

            &::before {
                background-color: $accent;
                transform: translate(-100%, 0);
                transition: transform 700ms ease;
            }
        }

        &:not(:disabled):not(.disabled) {
            &:focus,
            &:active,
            &:active:focus,
            &.active:focus {
                box-shadow: none;
                border: 0;
            }
        }

        &::before {
            @include vertical-center;

            background-color: $primary;
            content: '';
            height: rem-calc(1);
            left: 0;
            transition: transform 900ms ease;
            width: rem-calc(46);
        }
    }

    @include media-breakpoint-up(xxl) {
        font-size: rem-calc(16);
        font-weight: 500;
        letter-spacing: rem-calc(1.6);
        line-height: 1.5;
    }
}
/* stylelint-enable */
