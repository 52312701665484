/* stylelint-disable */
@mixin eyebrow($sm-font-size: 14, $sm-line-height: 1.15, $xxl-font-size: 16, $xxl-line-height: 1.5, $color: $accent, $multiple-size: true) {
    color: $color;
    display: block;
    font-family: $font-sans-serif;
    font-size: rem-calc($sm-font-size);
    font-weight: 600;
    letter-spacing: rem-calc(2);
    line-height: $sm-line-height;
    margin-bottom: rem-calc(22);
    text-transform: uppercase;

    @if $multiple-size {
        @include media-breakpoint-up(xxl) {
            font-size: rem-calc($xxl-font-size);
            line-height: $xxl-line-height;
        }
    }
}

@mixin heading($sm-font-size: 34, $sm-line-height: 1.35, $md-font-size: 50, $md-line-height: 1.5, $lg-font-size: 60, $lg-line-height: 1.3, $xxl-font-size: 64, $xxl-line-height: 1.5, $color: $primary, $multiple-size: true) {
    color: $color;
    font-family: $font-serif;
    font-size: rem-calc($sm-font-size);
    font-weight: 400;
    line-height: $sm-line-height;
    margin-bottom: rem-calc(22);

    @if $multiple-size {
        @include media-breakpoint-up(md) {
            font-size: rem-calc($md-font-size);
            line-height: $md-line-height;
            margin-bottom: rem-calc(16);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc($lg-font-size);
            line-height: $lg-line-height;
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc($xxl-font-size);
            line-height: $xxl-line-height;
        }
    }
}

@mixin description($sm-font-size: 16, $sm-line-height: 1.35, $md-font-size: 14, $md-line-height: 1.5, $xxl-font-size: 20, $xxl-line-height: 1.5, $color: $secondary, $multiple-size: true) {
    color: $color;
    font-family: $font-sans-serif;
    font-size: rem-calc($sm-font-size);
    font-weight: 400;
    letter-spacing: rem-calc(1);
    line-height: $sm-line-height;
    margin-bottom: 1.5em;

    @if $multiple-size {
        @include media-breakpoint-up(md) {
            font-size: rem-calc($md-font-size);
            line-height: $md-line-height;
            letter-spacing: normal;
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc($xxl-font-size);
            line-height: $xxl-line-height;
            letter-spacing: rem-calc(1);
        }
    }
}

@mixin alignText($xs: null, $sm: null, $md: null, $lg: null, $xl: null, $xxl: null) {
    @if $xs {
        text-align: $xs;
    }

    @if $sm {
        @include media-breakpoint-up(sm) {
            text-align: $sm;
        }
    }

    @if $md {
        @include media-breakpoint-up(md) {
            text-align: $md;
        }
    }

    @if $lg {
        @include media-breakpoint-up(lg) {
            text-align: $lg;
        }
    }

    @if $xl {
        @include media-breakpoint-up(xl) {
            text-align: $xl;
        }
    }

    @if $xxl {
        @include media-breakpoint-up(xxl) {
            text-align: $xxl;
        }
    }
}
/* stylelint-enable */
