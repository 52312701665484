@import '~site/variables';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/mixins/grid';
@import '~site/settings/variables';
@import '~site/mixins';

$custom-xxl-mbp: 1600px;

.homepage-highlights {
    position: relative;

    &__leaves {
        height: rem-calc(428);
        position: absolute;
        right: 0;
        top: rem-calc(-214);

        @include media-breakpoint-up(xxl) {
            top: rem-calc(-60);
        }
    }

    &__col-lg-left {
        @include media-breakpoint-up(lg) {
            padding-right: rem-calc(30);
        }
    }

    &__col-lg-right {
        @include media-breakpoint-up(lg) {
            padding-left: rem-calc(30);
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(154);
        margin-top: rem-calc(161);
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: rem-calc(204);
        margin-top: rem-calc(199);
    }
}

.products-highlights {
    &__intro {
        @include alignText($xs: center, $lg: left);

        padding-bottom: rem-calc(61);
        padding-top: rem-calc(54);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(90) 0 0;
        }

        @include media-breakpoint-up(xxl) {
            padding-top: 0;
        }
    }

    &__eyebrow {
        @include eyebrow();

        @include media-breakpoint-up(md) {
            margin-bottom: rem-calc(30);
        }

        @include media-breakpoint-up(xxl) {
            margin-bottom: rem-calc(36);
        }
    }

    &__heading {
        @include heading($multiple-size: false);

        @include media-breakpoint-up(md) {
            font-size: rem-calc(50);
            line-height: 1.3;
            margin-bottom: rem-calc(16);
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(60);
            line-height: 1.3;
            margin-bottom: rem-calc(15);
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(64);
            line-height: 1.25;
        }
    }

    &__description {
        @include description($sm-font-size: 14, $sm-line-height: 1.5, $multiple-size: false);

        margin-bottom: 0;

        p {
            color: inherit;
            font: inherit;
            line-height: inherit;
            margin-bottom: 1.5em;
        }

        @include media-breakpoint-up(md) {
            font-weight: 400;
            letter-spacing: normal;
        }

        @include media-breakpoint-up(lg) {
            max-width: rem-calc(536);
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(20);
            letter-spacing: rem-calc(1);
            max-width: rem-calc(470);
        }
    }

    &__btn-link {
        margin-top: rem-calc(28);

        @include media-breakpoint-only(md) {
            @include button-outline($primary);

            display: inline-block;
            margin-left: auto;
            margin-right: auto;
            padding-left: rem-calc(75);
            padding-right: rem-calc(75);
            -webkit-text-fill-color: unset;

            &::before {
                display: none;
            }

            &:hover {
                padding-left: rem-calc(75);
                padding-right: rem-calc(75);
                transition: all 0.3s;
            }
        }
    }

    &__slider {
        .slick-list {
            margin: 0 rem-calc(-5);
            overflow: hidden;
            padding-bottom: rem-calc(112) !important;
            padding-top: rem-calc(61) !important;

            @include media-breakpoint-up(sm) {
                margin: 0 rem-calc(2.5);
            }

            @include media-breakpoint-up(md) {
                margin: rem-calc(0 -13);
                padding-bottom: rem-calc(164) !important;
                padding-top: rem-calc(140) !important;
            }
        }

        .slick-dots {
            bottom: 0;
            margin: 0;
        }

        .slick-slide {
            margin: 0 rem-calc(5);

            @include media-breakpoint-up(md) {
                margin: 0 rem-calc(13);
            }
        }

        .arrow__container {
            &--prev {
                @include media-breakpoint-up(md) {
                    left: 0;
                    transform: rotate(45deg);
                }

                @include media-breakpoint-up(xl) {
                    left: rem-calc(8);
                }

                @include media-breakpoint-up(xl) {
                    left: rem-calc(10);
                }
            }

            &--next {
                @include media-breakpoint-up(md) {
                    right: 0;
                    transform: rotate(-45deg) scale(-1, 1);
                }

                @include media-breakpoint-up(lg) {
                    right: rem-calc(45);
                }
            }

            @include media-breakpoint-up(md) {
                top: rem-calc(50);
            }
        }

        .product--fullbleed {
            .product-tile {
                &__picture {
                    @include media-breakpoint-down(sm) {
                        height: rem-calc(382);
                    }
                }

                &__image-container {
                    @include media-breakpoint-down(sm) {
                        bottom: rem-calc(-84);
                        height: rem-calc(491);
                    }
                }

                &__name {
                    color: $accent;
                }

                &__bg {
                    display: none;
                }

                &__bg-container {
                    background-color: $beige;
                    position: relative;
                }
            }
        }
    }
}

.company-highlights {
    &__featured-image {
        .photo-tile-image {
            aspect-ratio: 320 / 254;
            max-height: 75vh;
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                aspect-ratio: 722 / 817;
                max-height: none;
            }
        }

        @include media-breakpoint-up(lg) {
            max-width: rem-calc(722);
            padding-top: 14.17%;
            position: relative;
        }
    }

    &__decoration {
        display: none;

        @include media-breakpoint-up(lg) {
            aspect-ratio: 712 / 916;
            background-color: $offwhite;
            display: block;
            height: 100%;
            left: rem-calc(-148px);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }
    }

    &__img-wrapper {
        overflow: hidden;
    }

    &__description {
        @include description($sm-line-height: 1.5, $multiple-size: false);

        margin: rem-calc(66) auto rem-calc(-42);
        max-width: 82vw;
        text-align: center;

        p {
            color: inherit;
            font: inherit;
            line-height: inherit;
            margin-bottom: 1.5em;
        }

        @include media-breakpoint-up(md) {
            font-size: rem-calc(14);
            font-weight: 400;
            letter-spacing: normal;
            margin: rem-calc(36 0 64);
            max-width: none;
            text-align: left;
        }

        @include media-breakpoint-up(lg) {
            margin: rem-calc(56 -15 100 0);
        }

        @include media-breakpoint-up(xxl) {
            font-size: rem-calc(20);
            letter-spacing: rem-calc(1);
        }
    }

    &__featured-badge {
        position: relative;
        z-index: 1;

        .photo-tile-image {
            aspect-ratio: 1 / 1;
            transform: translateY(50%);
            width: 24.37vw;

            @include media-breakpoint-up(md) {
                transform: translateY(-32px);
                width: rem-calc(136);
            }
        }

        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__badges {
        .photo-tile-image {
            width: auto;

            @include media-breakpoint-between(md, xl) {
                height: rem-calc(167);
            }
        }

        @include media-breakpoint-down(md) {
            margin-bottom: rem-calc(60);
            text-align: center;
        }
    }
}
