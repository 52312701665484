//* * * * * * * * * Font-sizes * * * * * * * * *
$base16-16px: 16px;
$base16-32px: 32px;

//* * * * * * * * * Colors * * * * * * * * *
$primary: #362524;
$secondary:#7c605f;
$accent: #861723;
$light: #ecebd6;
$offwhite: #faf8f7;
$gray: #999;
$offdark: #202020;
$offgray: #e5e5e5;
$darklogo: #1a1919;

$dark-black: #131415;
$dark-black2: #141415;
$light-white: #f4efe5;
$light-white2: #f5efe4;
$light-white3: #ececd6;
$light-white4: #ecebd6;
$light-white5: #ede6e2;
$light-white6: #aeaeae;
$light-white7: #ebe2de;
$light-white8: #cacaca;
$light-white9: #ebe2de;
$light-white10: #ececd6;
$light-white11: #c5beac;
$light-white12:#e0e0e0;
$light-white13:#faf8f7;
$grey: #aeaeae;
$grey2: #cacaca;
$grey3: #dedede;
$grey4: #e9e9e9;
$grey5: #ccc;
$grey6: #666;
$grey7: #ddd;
$light-grey: #e1e1e1;
$regular-grey: #969696;
$dark-grey: #575757;
$dark-brown: #241919;
$dark-brown1: #452615;
$orange: #f96e19;
$dark-orange: #d0a95f;
$dark-purple: #1f0614;
$smoke: #283234;
$sidebar-bg: #291918;

$gray-box-shadow: rgba(166, 166, 166, 0.5);

$hint-of-red: #faf8f7;
$pampas: #efe9e6;
$pearl-blush: #ebe2de;
$champagne-pink: #e6dcd7;
$alto: #d7d7d7;
$silver-chalice: #aeaeae;
$bleach-white: #fef0d9;
$white-rock: #ececd6;
$anzac: #e6ab53;
$anzac-hover: #ebbc74;
$anzac-active: #eb973b;
$sundance: #d0a95f;
$hokey-pokey: #c29f2f;
$carrot-orange: #f18524;
$chatham-blue: #145a77;
$cocoa-brown: #362524;
$woodsmoke: #161616;
$beige: #ebe2de;
$cultured: #f2f2f2;
$jasmine: #ffe898;
$blond: #f8efb9;

//* * * * * * * * * Fonts * * * * * * * * *
$font-serif: 'Cardo', serif; // 400
$font-sans-serif: 'Work Sans', sans-serif; // 300, 400, 500, 600, 700
$font-cakebread-icons: 'CakeBreadIcons' !default;
