@mixin absolute-center {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin horizontal-center {
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
}

@mixin vertical-center {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

// Add margin top taking in account the presence of the header banner
@mixin mt-header-banner($mt-xs: null, $mt-sm: null, $mt-md: null, $mt-lg: null, $mt-xl: null, $mt-xxl: null) {
    $sm-header-banner: 34;
    $lg-header-banner: 41;

    @if $mt-xs {
        margin-top: rem-calc($mt-xs);

        .has-header-banner & {
            margin-top: rem-calc($mt-xs + $sm-header-banner);
        }
    }

    @if $mt-sm {
        @include media-breakpoint-up(sm) {
            margin-top: rem-calc($mt-sm);

            .has-header-banner & {
                margin-top: rem-calc($mt-sm + $sm-header-banner);
            }
        }
    }

    @if $mt-md {
        @include media-breakpoint-up(md) {
            margin-top: rem-calc($mt-md);

            .has-header-banner & {
                margin-top: rem-calc($mt-md + $sm-header-banner);
            }
        }
    }

    @if $mt-lg {
        @include media-breakpoint-up(lg) {
            margin-top: rem-calc($mt-lg);

            .has-header-banner & {
                margin-top: rem-calc($mt-lg + $lg-header-banner);
            }
        }
    }

    @if $mt-xl {
        @include media-breakpoint-up(xl) {
            margin-top: rem-calc($mt-xl);

            .has-header-banner & {
                margin-top: rem-calc($mt-xl + $lg-header-banner);
            }
        }
    }

    @if $mt-xxl {
        @include media-breakpoint-up(xxl) {
            margin-top: rem-calc($mt-xxl);

            .has-header-banner & {
                margin-top: rem-calc($mt-xxl + $lg-header-banner);
            }
        }
    }
}

// Add margin taking the height of the solid header in account
@mixin mt-solid-header() {
    margin-top: rem-calc(74);

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(101);
    }

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(130);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(119);
    }
}

// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.
///
/// @returns {List} A list of converted values.

@function rem-calc($values, $base: null) {
    $rem-values: ();
    $count: length($values);

    // If no base is defined, defer to the global font size
    @if $base ==null {
        $base: $base16-16px;
    }

    @if $count ==1 {
        @return -zf-to-rem($values, $base);
    }

    @for $i from 1 through $count {
        $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
    }

    @return $rem-values;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.

@function -zf-to-rem($value, $base: null) {

    // Check if the value is a number
    @if type-of($value) !='number' {
        @warn inspect($value)+' was passed to rem-calc(), which is not a number.';

        @return $value;
    }

    // Calculate rem if units for $value is not rem
    @if unit($value) !='rem' {
        $value: strip-unit($value) / strip-unit($base) * 1rem;
    }

    // Turn 0rem into 0
    @if $value ==0 {
        $value: 0;
    }

    @return $value;
}

/// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
///
/// @param {Number} $num - Number to strip unit from.
///
/// @returns {Number} The same number, sans unit.

@function strip-unit($num) {
    @return $num / ($num * 0 + 1);
}
