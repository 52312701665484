/* stylelint-disable */
@mixin form-control() {
    border: rem-calc(1) solid $gray;
    border-radius: 0;
    height: rem-calc(44);
    color: $secondary;
    font-size: rem-calc(16);
    line-height: 1.2;
    letter-spacing: rem-calc(1);
    font-weight: 400;

    @include media-breakpoint-up(lg) {
        letter-spacing: normal;
    }

    &:focus,
    &:active {
        box-shadow: 0 0 0 rem-calc(3) rgba($accent, 0.25);
    }

    &:focus {
        border-color: $gray;
        color: $secondary;
    }

    &.is-invalid {
        color: $accent;
        padding-right: rem-calc(40) !important;
    }
}

@mixin form-control-label() {
    font-weight: 300;
    font-size: rem-calc(14);
    line-height: 1.36;
    letter-spacing: rem-calc(1);
    color: $secondary;
    margin-bottom: rem-calc(8);

    @include media-breakpoint-up(lg) {
        font-weight: 400;
        letter-spacing: normal;
    }
}

@mixin form-select($hollow: false) {
    position: relative;

    &::after {
        content: $fa-var-chevron-down;
        display: inline-block;
        font-family: $font-cakebread-icons;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @if $hollow {
            color: $woodsmoke;
            font-size: rem-calc(13);
            right: rem-calc(5);
        } @else {
            color: $secondary;
            font-size: rem-calc(15);
            right: rem-calc(11);

            @include media-breakpoint-up(lg) {
                right: rem-calc(17);
            }
        }
    }

    select {
        appearance: none;
        background: transparent;
        border-radius: 0;
        font-family: $font-sans-serif;
        letter-spacing: 0;
        min-height: auto;
        padding: 0;
        position: relative;

        @if $hollow {
            border: 0;
            border-bottom: rem-calc(1) solid $woodsmoke;
            height: rem-calc(24);
            min-width: rem-calc(50);
            padding-left: rem-calc(7);
            padding-right: rem-calc(24);
            color: $woodsmoke;
            font-size: rem-calc(16);
            line-height: 1.5;
            font-weight: 300;
        } @else {
            border: rem-calc(1) solid $gray;
            height: rem-calc(44);
            padding-right: rem-calc(37);
            padding-left: rem-calc(12);
            color: $secondary;
            font-size: rem-calc(16);
            line-height: 1.2;
            letter-spacing: rem-calc(1);
            font-weight: 400;

            @include media-breakpoint-up(lg) {
                letter-spacing: normal;
            }
        }

        &:focus,
        &:active {
            box-shadow: 0 0 0 rem-calc(3) rgba($accent, 0.25);
        }

        &.is-invalid {
            color: $accent;
            border-color: $accent;

            @if $hollow {
                padding-right: rem-calc(24) !important;
            } @else {
                padding-right: rem-calc(40) !important;
            }
        }
    }

    option {
        color: $woodsmoke;
        font-size: rem-calc(14);
        line-height: rem-calc(34);
        padding: 0 rem-calc(12);
    }
}

@mixin form-check($round: true) {
    position: relative;

    @if $round {
        padding-left: rem-calc(24);
    } @else {
        padding-left: rem-calc(32);
    }

    input {
        @include form-check-input($round);
    }

    label {
        @include form-check-label();
    }
}

@mixin form-check-input($round: true) {
    background: $white;
    border: rem-calc(1) solid $accent;
    appearance: none;
    margin: 0;
    position: absolute;
    top: rem-calc(2);
    left: 0;

    @if $round {
        border-radius: 50%;
        width: rem-calc(16);
        height: rem-calc(16);
    } @else {
        border-radius: rem-calc(2);
        width: rem-calc(21);
        height: rem-calc(21);
    }

    &::after {
        content: $fa-var-check;
        display: block;
        color: $white;
        font-family: $font-cakebread-icons;
        position: absolute;
        transform: scale(0);
        transition: transform 0.3s;

        @if $round {
            left: rem-calc(2);
            top: rem-calc(1);
            font-size: rem-calc(10);
        } @else {
            left: rem-calc(2);
            top: 0;
            font-size: rem-calc(16);
            line-height: rem-calc(21);
        }
    }

    &:checked {
        background: $accent;

        &::after {
            transform: scale(1);
        }
    }
}

@mixin form-check-label() {
    font-weight: 400;
    font-size: rem-calc(14);
    line-height: rem-calc(19);
    letter-spacing: rem-calc(1);
    color: $primary;
    padding: 0;

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(16);
    }
}
/* stylelint-enable */
